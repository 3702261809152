import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import { useRef, useState } from "react";
import { useGSAP } from "@gsap/react";
import { useNavigate } from "react-router-dom";

import Nav from "../components/Nav";
import "../styles/homeAnimations.css";
import Particles from "../components/Particles";

gsap.registerPlugin(useGSAP);

// TODO: add sparkles or emoticons when clicking

export default function Home() {
  const container = useRef();
  const navigate = useNavigate();

  const [helloWorldText, updateHelloWorldText] =
    useState("");

  function handleHelloWorldSubmit(e) {
    if (helloWorldText === "world") {
      navigate("./world");
    }
  }

  useGSAP(
    () => {
      gsap.config({ trialWarn: false });
      console.clear();
      gsap.registerPlugin(ScrollTrigger);
      const split = new SplitType("p", { type: "lines" });

      split.lines.forEach((target) => {
        gsap.to(target, {
          backgroundPositionX: 0,
          ease: "none",
          scrollTrigger: {
            trigger: target,
            scrub: 1,
            start: "top center",
            end: "bottom center",
          },
        });
      });
    },
    { scope: container }
  );

  return (
    <div className="home">
      <Nav pageTitle="egret" />
      <div className="content">
        <div className="home-links">
          <Link to={`/about`}>about</Link>
          <Link to={`/programming`}>programming</Link>
          <Link to={`/design`}>design</Link>
          <Link to={`/fun`}>fun</Link>
        </div>
        <div class="marquee marquee--hover-pause">
          <div class="marquee-content">
            <div>
              the real voyage of discovery consists not in
              seeking new landscapes, but in having new
              eyes.
            </div>
            <div>
              the real voyage of discovery consists not in
              seeking new landscapes, but in having new
              eyes.
            </div>
          </div>
          <div aria-hidden="true" class="marquee-content">
            <div>
              the real voyage of discovery consists not in
              seeking new landscapes, but in having new
              eyes.
            </div>
            <div>
              the real voyage of discovery consists not in
              seeking new landscapes, but in having new
              eyes.
            </div>
          </div>
        </div>
        <div
          ref={container}
          className="scroll-thoughts prevent-select"
        >
          <p>
            {/* tomorrow, and tomorrow, and tomorrow, creeps in
          this petty pace from day to day, to the last
          syllable of recorded time; and all our yesterdays
          have lighted fools. the way to dusty death. out,
          out, brief candle! life's but a walking shadow, a
          poor player, that struts and frets his hour upon
          the stage, and then is heard no more. it is a tale
          told by an idiot, full of sound and fury,
          signifying nothing. */}
            {/* i want to use the internet like a massive chat
          room again. public back and forth with friends,
          silly arguments when you should be sleeping,
          replying to everything, no fear of who sees.
          somewhere down the line, every move made online
          became part of some public portfolio. every like
          or comment or post became an endorsement or part
          of a brand. we're all just people living and
          sharing, not a PR team clocking in every day to
          sell our self image. */}
            {/* just when you need them most the words won't
            come out i think i crave solitude more and more
            these days and the annoyance of another is too
            much. */}
            there is something lonely between us these days.
            AI accidentally made me believe in the concept
            of a human soul by showing me what art looks
            like without it.
            memory lane is a dimly lit bus stop. i sit waiting and waiting;
            the bus never arrives.
          </p>
        </div>
        <form onSubmit={handleHelloWorldSubmit}>
          <input
            type="text"
            onChange={(e) =>
              updateHelloWorldText(e.target.value)
            }
            placeholder="hello"
            value={helloWorldText}
          />
          <input
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </div>
      <Particles />
    </div>
  );
}
