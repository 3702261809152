import { useState } from "react";
import Nav from "../components/Nav";
import ImageNav from "../components/portfolio/ImageNav";
import { useWindowSize } from "../hooks/useWindowSize";

export type Project = {
  name: string;
  summary: string;
  mainImg: string;
  images: string[];
  info: string;
  videos: string[];
};

// TODO: update this to get first value from array or w/e

const initialProject: Project = {
  name: "test project",
  summary:
    "this is sample text for the description. i don't think i'll need more than a couple lines, so this will do for now",
  mainImg: "",
  images: [],
  info: "",
  videos: [],
};

export const projects: Project[] = [
  {
    name: "project 1",
    summary:
      "this is sample text for the description. i don't think i'll need more than a couple lines, so this will do for now",
    mainImg: "",
    images: [],
    info: "",
    videos: [],
  },
  {
    name: "project 2",
    summary:
      "this is different text for the description. i don't think i'll need more than a couple lines, so this will do for now",
    mainImg: "",
    images: [],
    info: "",
    videos: [],
  },
  {
    name: "project 3",
    summary:
      "this is even more different text for the description. i don't think i'll need more than a couple lines, so this will do for now",
    mainImg: "",
    images: [],
    info: "",
    videos: [],
  },
];

export default function Programming() {
  const size = useWindowSize();

  const [currentProject, setCurrentProject] =
    useState<Project>(initialProject);

  // consult notebook for layout plans

  return (
    <div>
      <Nav pageTitle="programming" back="true" />
      <p>
        this is where i would show my work but i just got
        this cool scrolling thing set up
      </p>
      {size[0] < 800
        ? " (personally i think this looks better on desktop but you do you)"
        : ""}
      <ImageNav currentProject={currentProject} />
      <div
        style={{
          height: "600px",
        }}
      >
        bottom text
      </div>
    </div>
  );
}
