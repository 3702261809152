import React from "react";
import Nav from "../components/Nav";

export default function Resume() {
  const [password, setPassword] = React.useState("");
  const [unlocked, setUnlocked] = React.useState(false);

  // TODO: get back nav bar to show up with resume and
  // also get rid of weird spacing
  return (
    <div className="full-page">
      {unlocked ? (
        <object
          aria-label="resume "
          data="/resume.pdf"
          type="application/pdf"
          width={"100%"}
          height={"100%"}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Nav pageTitle="" back="true" />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (password === "please") {
                setUnlocked(true);
              }
            }}
          >
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="what do we say?"
            />
            <input
              type="submit"
              style={{ display: "none" }}
            />
          </form>
        </div>
      )}
    </div>
  );
}
