import { Link } from "react-router-dom";

import Nav from "../components/Nav";
import Vibes from "../components/Vibes";

const words = [
  "she's just like me",
  "this and a bowl of trail mix",
  "did u get my vibes ? im thinking we r all going 2 b ok",
  "if i'm not drowning in it i have no desire 4 it",
  "restless",
  "i like hearing your dreams",
  "almost summer here. miss u",
];

export default function About() {
  const word = Math.floor(Math.random() * words.length);
  return (
    <div className="page about">
      <Nav pageTitle="about" back="true" />
      <div className="content">
        <Vibes />
        {words[word]}
        <br />
        <br />
        <br />
        my design process is iterative. art begets work. i
        am always learning.
        <br />
        <br />
        speak to me
        <br />
        <Link to={`./resume`}>cv</Link>
      </div>
    </div>
  );
}
