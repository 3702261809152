import "../../styles/imageNav.css";
import { Project } from "../../pages/Programming";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { projects } from "../../pages/Programming";

// TODO: get rid of weird padding lol
export default function ImageNav({
  currentProject,
}: {
  currentProject: Project;
}) {
  // this will control the state of the page and what's being shown
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    let sections = gsap.utils.toArray(".section");

    const calculatedValue = 1 / (sections.length - 1);

    let scrollTween = gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none", // <-- IMPORTANT!
      scrollTrigger: {
        trigger: ".image-nav-container",
        pin: true,
        scrub: 0.1,
        snap: directionalSnap(
          calculatedValue
        ) as ScrollTrigger.SnapFunc,
        end: "+=3000",
      },
    });

    // // helper function for causing the sections to always snap in the direction of the scroll (next section) rather than whichever section is "closest" when scrolling stops.
    function directionalSnap(increment: number) {
      let snapFunc = gsap.utils.snap(increment);
      return (raw: number, self: { direction: number }) => {
        let n = snapFunc(raw);
        return Math.abs(n - raw) < 1e-4 ||
          n < raw === self.direction < 0
          ? n
          : self.direction < 0
          ? n - increment
          : n + increment;
      };
    }
  });

  // TODO: add project contents
  return (
    <div className="image-nav-container">
      <div
        className="scroll-container"
        id="scrollContainer"
      >
        <div className="section ">Section 1</div>
        <div className="section ">Section 2</div>
        <div className="section ">Section 3</div>
        <div className="section ">Section 4</div>
        <div className="section ">Section 5</div>
      </div>
    </div>
  );
}
