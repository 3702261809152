import Nav from "../components/Nav";

export default function Design() {
  return (
    <div>
      <Nav pageTitle="design" back="true" />
      - ton of stuff from school <br />
      - housing website <br />
      - nyc chess rating system <br />
      - my instagram page <br />
      - this website <br />
      - old portfolio websites
      <br />
    </div>
  );
}
